.root {
  position: relative;
  cursor: pointer;
  transition-duration: .2s;
  z-index: 0;
}

.rootMd {
  width: 56px;
  height: 56px;
}

.rootXs {
  width: 44px;
  height: 44px;
}

.roundIcon {
  position: absolute;
  top: 2px;
  left: 2px;
  transition-duration: .2s;
  z-index: 1;
}

.inside {
  z-index: 10;
  position: absolute;
  top: -2px;
  left: -2px;
  transition-duration: .2s;
}

.insideIcon {
  border-radius: 550px;
  backdrop-filter: blur(4px);
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.root:hover .icon path {
  stroke: rgba(37, 134, 255, 1);
  transition-duration: .2s;
}

.root:hover .insideIcon svg {
  transition-duration: .2s;
  fill: rgba(255, 255, 255, 0.7);
}

.root:active .icon path {
  stroke: rgba(255, 255, 255, 1);
  transition-duration: .2s;
}

.root:active .inside {
  top: -1px;
  left: -1px;
  transition-duration: .2s;
}

.root:active .insideIcon svg {
  transition-duration: .2s;
  fill: rgba(87, 162, 255, 0.3);
}

.root:active .icon path {
  stroke: rgba(255, 255, 255, 1);
  transition-duration: .2s;
}

.root:active .inside {
  top: -1px;
  left: -1px;
  transition-duration: .2s;
}

.root:active .insideIcon svg {
  transition-duration: .2s;
  fill: rgba(87, 162, 255, 0.3);
}

.activeRoot {
  transition-duration: .2s;
  top: -1px;
  left: -1px;
}

.activeRoot .insideIcon svg {
  transition-duration: .2s;
  fill: rgba(87, 162, 255, 0.3);
}

.activeRoot .icon path {
  stroke: rgba(255, 255, 255, 1);
  transition-duration: .2s;
}



.disabled:hover .icon path{
  cursor: not-allowed;
  stroke: rgba(136, 136, 136, 1);
}

.disabled:hover .insideIcon svg {
  transition-duration: .2s;
  fill: rgba(255, 255, 255, 0.7);
}

.disabled:active .icon path{
  cursor: not-allowed;
  stroke: rgba(136, 136, 136, 1);
}

.disabled:active .insideIcon svg {
  transition-duration: .2s;
  fill: rgba(255, 255, 255, 0.7);
}

.disabled .insideIcon svg {
  transition-duration: .2s;
  fill: rgba(255, 255, 255, 0.7);
}

.disabled:active .inside {
  top: 0;
  left: 0;
}

.disabled .inside {
  top: 0;
  left: 0;
}

.disabled {
  cursor: not-allowed;
}

.disabled .icon path {
  stroke: rgba(136, 136, 136, 1);
}
